@import 'react-datepicker/dist/react-datepicker.css';

.react-datepicker__input-container input {
  max-width: 70px;
  height: 30px;
}

.react-datepicker__input-container :hover {
  cursor: pointer;
}

.react-datepicker__day--selected {
  background-color: rgb(78, 81, 84);
}

.react-datepicker__day--keyboard-selected {
  color: white;
  background-color: rgb(78, 81, 84);
}

.react-datepicker__day--selected:hover {
  background-color: rgb(78, 81, 84);
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: rgb(78, 81, 84);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: rgb(78, 81, 84);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: rgb(78, 81, 84);
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
  height: 41px;
}
