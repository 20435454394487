@keyframes slidein {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-30%);
  }
}

.participant-name-number-sliding-animation {
  animation: slidein 10s linear 0s infinite alternate;
}
